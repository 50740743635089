<template>
<div class="row">
  <div class="col-md-12">
    <div class="text-end" >
        <button class="btn  btn-sm btn-primary" type="button" v-show="!articlesearch" v-on:click="articlesearch = !articlesearch; subscribe = true; unsubscribe = false;">
            Add Article
        </button>
      
        <button class="btn btn-sm btn-primary" type="button" v-show="articlesearch" v-on:click="articlesearch = !articlesearch;">
            Back To Current Articles
        </button>
    
    </div>
  </div>
  <div class="col-md-12 mtop-15" v-show="articlesearch" >
    <!-- Custom Tabs -->
    <div class="form-horizontal">
            
        <div class="form-group">
            <label for="inputEmail3" class="col-2 control-label text-left">Topics</label>

            <div class="col-10">
                <select class="form-control">
                    <option value="Select Topics">Select Topics</option>
                </select>
            </div>
        </div>	
        <div class="form-group">
            <div class="col-md-12">
                <div class="input-group">
                    <input type="text" class="form-control">
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-primary btn-flat">Search Selected Topics</button>
                    </span>
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="row">
        <div class="col-md-12 ">
            <div class="box box-info ng-scope">
                <div class="box-header with-border">
                    <i class="fa  fa-list"></i>
                    <h3 class="box-title head-title-padding">Search
                        Results</h3>
                </div>
                <!-- /.box-header -->
                <!-- form start -->
                <div class="box-body">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="no-sort" name="name" width="30">
                                <input type="checkbox" class="form-check-input mright-5">
                                </th>
                                <th class="text-capitalize">
                                    Subject
                                </th>
                                <th class="text-capitalize">
                                    Topic
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="row1">
                                <td name="name" width="30">
                                    
                                    <input type="checkbox" class="form-check-input mright-5">
                                </td>
                                <td>
                                    How to change your computer resolution
                                </td>
                                <td>
                                    All Topic
                                </td>	
                            </tr>

                            <tr id="row2">
                                <td name="name" width="30">
                                    
                                    <input type="checkbox" class="form-check-input mright-5">
                                </td>
                                <td>
                                    How to log in to your EA Account for EA Help
                                </td>
                                <td>
                                    All Topic
                                </td>
                            </tr>

                            <tr id="row3">
                                <td name="name" width="30">
                                    
                                    <input type="checkbox" class="form-check-input mright-5">
                                </td>
                                <td>
                                    Redeem your serial key or product code in Origin
                                </td>
                                <td>
                                    All Topic
                                </td>	
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-12 mtop-15" v-show="!articlesearch">
    <div class="box box-info">
        <div class="box-body with-border">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <!--<th class="no-sort"  name="name" width="30"></th>-->
                        <th class="text-capitalize">
                            Subject
                        </th>
                        <th class="text-capitalize">
                            Topic
                        </th>
                        <th>
                        </th>	
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Getting EA Classics up and running on your modern OS
                        </td>
                        <td>
                            All Topic
                        </td>
                        <td>
                           <button class="btn button-delete"><i class="fa fa-times-circle"></i></button>
                        </td>	
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
  </div>
  <div class="col-md-12" v-show="articlesearch && subscribe">
            <button class="btn btn-sm btn-primary mright-5" type="button" v-on:click="articlesearch = !articlesearch">
                Add Selected
            </button>
            <span>Add only selected article to the list.</span>
   </div>
</div>
</template>

<script>

export default {
  name: 'Related Articles',
  data() {
      return{
          articlesearch : false,
      }
  },
  components: {

  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>