<template>
 <div class="dropdown">
  <button class="btn btn-default btn-sm dropdown-toggle responses " type="button" id="dropdownMenuButton1" v-on:click="vsdropdown = !vsdropdown">
    Stock Responses
  </button>
  <div class="dropdown-menu show dropdown-menu-right  dropdown-response" aria-labelledby="dropdownMenuButton1" v-if="vsdropdown">
  	<div class="variables">
      <div class="col-md-12">   
      <div class="btn-group" data-toggle="btn-toggle">
        <a class="btn btn-default btn-sm" data-toggle="on" href="javascript:void(0)" v-bind:class = "builtinForm?'active btn-primary':''"  v-on:click="customForm = true; builtinForm = false">Stock Responses</a>
      </div>
      </div>   
      <div class="col-md-12  mtop-15">
      <div class="row form-group">
        <label class="col-md-12 control-label text-left">Filter</label>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Filter Stock Responses">
        </div>
      </div>
      </div>
      <div class="col-12" v-if="customForm">
        <div class="custom-scroll">
          <ul class="list-unstyled segment-variable-list">
          <li class="section-label">
            Contact
            <ul class="segment-variables">
            <li class="section-var-link">Name</li>
            <li class="segment-lable">
              Global
              <ul>
              <li class="section-var-link">Address1</li>
              <li class="section-var-link">Address2</li>
              <li class="section-var-link">City</li>
              <li class="section-var-link">Country</li>
              <li class="section-var-link">Company</li>
              </ul>
            </li>
            <li class="segment-lable">
              JC Segment
              <ul>
              <li class="section-var-link">Contact View Only Property</li>
              <li class="section-var-link">Contact Viewable and Editable Property</li>
              <li class="section-var-link">Multi value No Descr</li>
              <li class="section-var-link">Shared Property from JC - cust can't see</li>
              </ul>
            </li>
            </ul>
          </li>
          </ul>
          <ul class="list-unstyled segment-variable-list">
          <li class="section-label">
            Answer
            <ul class="segment-variables">
            <li class="section-var-link">Email</li>
            <li class="section-var-link">Reference Number</li>
            <li class="section-var-link">Original Date</li>
            <li class="section-var-link">Original Time</li>
            <li class="section-var-link">Subject</li>
            <li class="section-var-link">Original Body</li>
            <li class="section-var-link">Quoted Question</li>
            </ul>
          </li>
          </ul>
        </div>
      </div>
      <div class="col-12" v-if="builtinForm">
        <div class="custom-scroll">
          <ul class="list-unstyled segment-variable-list">
          <li class="section-label">
            Segment Responses
            <ul class="segment-variables">
            <li class="section-var-link"><i class="fa fa-eye mright-5" uib-tooltip="Also, if you need to access this information in the future you can find it in your interaction history of iService. Just click here and login with your email address. if you dont't know your password" tooltip-placement="right"></i>Chat Closing</li>
            <li class="section-var-link"><i class="fa fa-eye mright-5" uib-tooltip="Also, if you need to access this information in the future you can find it in your interaction history of iService. Just click here and login with your email address. if you dont't know your password" tooltip-placement="right"></i>Chat Opening</li>
            <li class="section-var-link"><i class="fa fa-eye mright-5" uib-tooltip="Also, if you need to access this information in the future you can find it in your interaction history of iService. Just click here and login with your email address. if you dont't know your password" tooltip-placement="right"></i>Chat Wait</li>
            <li class="section-var-link"><i class="fa fa-eye mright-5" uib-tooltip="Also, if you need to access this information in the future you can find it in your interaction history of iService. Just click here and login with your email address. if you dont't know your password" tooltip-placement="right"></i>iService Website</li>
            </ul>
          </li>
          </ul>
          <ul class="list-unstyled segment-variable-list">
          <li class="section-label">
            Agent Response
            <ul class="segment-variables">
            <li class="section-var-link"><i class="fa fa-eye mright-5" uib-tooltip="Also, if you need to access this information in the future you can find it in your interaction history of iService. Just click here and login with your email address. if you dont't know your password" tooltip-placement="right"></i>Test</li>
            </ul>
          </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'StockResponsesComponents',
   components: {
  },
  data: function(){
      return{
        vsdropdown : false,
        customForm : false,
        builtinForm : true
      }
  }
}
</script>

