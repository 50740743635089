<template>
 <div class="row">
    <div class="col-md-12">    
        <div class="nav-tabs-custom mtop-15">
            <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item" role="presentation">
                    <a class="nav-link active" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'This interaction has file attachments.',theme: {placement: 'bottom', }}"><i class="fa fa-paperclip"></i> <span>Details & Attachments</span><span class="new-inside"></span></a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property value set for this interaction.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Article Properties</span><span class="new-inside"></span></a>
                </li>
                
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab4'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_4_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" ><i class="fa fa-fw fa-bell"></i> <span>Subscribers List</span></a>
                </li>
                
            </ul>
            <div class="tab-content" id="myTabContent">
            
                <div class="tab-pane show active" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <div class="row">
                    <div class="message-box-header">
                        <div class="col-md-12">
                            <div class="det-box-bg">
                               <div class="row">
                                    <div class="col-md-6"> 
                                            <div><b>Article ID : </b>4645646</div>
                                            <div><b> Topic : </b>All Topic</div>	
                                            <div><b> Date Updated : </b>1/19/2019 11:58:45 PM</div>
                                    </div>
                                    <div class="col-md-6"> 
                                        <div><b> # Views : </b>11</div>
                                        <div><b> Article Creator : </b>Alek Packard</div>									
                                    </div>
                                </div>
                            </div>	
                        </div>	
                        <div class="form-group">
                            <div class="col-md-12 mtop-15">
                                <div class="drop-box">Drop files here or click to upload </div>
                            </div>	
                        </div>
                        <div class="col-md-12 mtop-15">
                          <b><i class="fa fa-paperclip attachment-icon"></i></b> :
                               <a href="" class="attachments">
                                 <i class="fa fa-file-image-o"></i> 
                                 <span class="filename">Image2.jpg (30kb)</span>
                                 <span>
                                   <button class="icon-button button-delete" type="button">
                                     <i class="fa fa-times-circle"></i>
                                   </button>
                                 </span>
                               </a> 

                               <a href="" class="attachments">
                                 <i class="fa fa-file-image-o"></i> 
                                 <span class="filename">Image2.jpg (30kb)</span>
                                 <span>
                                   <button class="icon-button button-delete" type="button">
                                     <i class="fa fa-times-circle"></i>
                                   </button>
                                 </span>
                               </a> 
                             </div>
                        <div class="col-md-12 mtop-15">
                          <button type="button" class="btn btn-primary" ng-click="updateArticle(row)">Save</button>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <InteractionPropertyTab></InteractionPropertyTab>
                </div>
            
                <div class="tab-pane fade" v-bind:id="'tab_4_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                    <div class="row">
                        <div class="col-md-7">
                            <h4 class="smtp-out-message"> Number of contacts subscribed to this
                                list : <span class=" text-orange"><b>1234</b></span>
                                <i class="fa fa-question-circle text-info help-icon"></i>
                            </h4>
                        </div>
                        <div class="col-md-5 text-right">
                            <div class="form-group pull-right">
                                <button class="btn  btn-sm btn-primary" type="button">
                                    Send Notifications To Subscribers
                                </button>
                                <i class="fa fa-question-circle text-info help-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-12">
                           <div class="notdatatable form-inline mbottom-15"><div class="table-controls table-columns"><div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div></div><div class="table-controls table-pagesize table-columns"><label> Show <select class="form-control input-xs"><option>5</option><option>10</option><option>25</option><option>50</option><option>100</option><option>250</option><option>500</option><option>1000</option></select> entries </label></div><div class="table-controls table-filter">Filter Subscribers  <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div></div>
                           <table class="table table-striped">
                             <thead>
                                <tr role="row">
                                    <th class="sorting sorting_asc"> First Name </th>
                                    <th class="sorting"> Last Name </th>
                                    <th class="sorting"> Email </th>
                                    <th class="sorting"> Company </th>
                                    <th class="sorting"> Phone (Work) </th>
                                </tr>
                            </thead>
                             <tbody>
							<tr role="row" class="odd">
									<td class="sorting_1">
										Albert
									</td>
									<td>
										Metricman
									</td>
									<td>
										<a href="">albert.metricman@example.com</a>
									</td>
									<td>
										Example Company, Inc.
									</td>
									<td>
										312-555-5555
									</td>
								</tr><tr role="row" class="even">
									<td class="sorting_1">
										Howard
									</td>
									<td>
										Smith
									</td>
									<td>
											<a href="">howard.smith@example.com</a>
									</td>
									<td>
										Example Network
									</td>
									<td>
										487-555-5222
									</td>
								</tr><tr role="row" class="odd">
									<td class="sorting_1">
										John
									</td>
									<td>
										Doe
									</td>
									<td>
											<a href="">john.doe@example.com</a>
									</td>
									<td>
										Example Company, Inc.
									</td>
									<td>
										312-555-5555
									</td>
								</tr><tr role="row" class="even">
									<td class="sorting_1">
										Julie
									</td>
									<td>
										Persuavia
									</td>
									<td>
											<a href="">julie.persuavia@example.com</a>
									</td>
									<td>
										Example Company, Inc.
									</td>
									<td>
										312-555-5555
									</td>
								</tr><tr role="row" class="odd">
									<td class="sorting_1">
										Robert
									</td>
									<td>
										Williams
									</td>
									<td>
											<a href="">robert.williams@example.com</a>
									</td>
									<td>
										Example Network
									</td>
									<td>
										487-555-5222
									</td>
								</tr></tbody>
                           </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <EditKbArticle v-if="editquestion"></EditKbArticle>
        <div class="message-box">
            <button class="btn btn-xs btn-link pull-right" v-show="!editquestion" v-on:click="editquestion = !editquestion">
                <i class="fa fa-pencil"></i>
            </button>

            
            <div class="message-box-body" v-show="!editquestion"> 
                <div class="clear"></div>
                    <div>										   
                    <p><b>Question:</b><br>
                        How do i get started with Customer Portal?
                    </p>
                    <p><b>Answer:</b><br>
                        To use Customer Portal, you'll first need to register using Sign Up link. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import EditKbArticle from '../components/EditKbArticle.vue';

import StatusAudit from '../components/StatusAudit.vue';
import FullCaseHistory from '../components/FullCaseHistory.vue';
import Toggle from '@vueform/toggle'
import RelatedArticleTab from '../components/RelatedArticleTab.vue';
import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Expanded Row',
      props: {showGetThis:Boolean,showAnswer:Boolean},
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        StatusAudit,
        FullCaseHistory,
        Toggle,
        EditKbArticle,
        RelatedArticleTab
        
      },
      mounted(){
           if(this.showGetThis == 0){
               this.showget = false;
           }else{
              this.showget = true;
           }

            if(this.showAnswer == 0){
               this.showanswer = false;
           }else{
              this.showanswer = true;
           }
        },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            toggleMore : true,
            statusaudit : false,
            fullcasehistory : false,
            palintext: true,
            showget : Boolean(this.showGetThis),
            showanswer : Boolean(this.showGetThis),
            editquestion: false
          }
      },
       methods: {
            show(segment){
              this.emitter.emit("show-view-details",{displaySegment:segment});
            }
            
        }
    }
</script>

